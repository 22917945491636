import React from 'react'

export function SvgTextLogo({ width = 112, height = 21, className }) {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 112 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.0794 5.50986C12.7873 5.50986 15.6868 8.4083 15.6868 12.9267C15.6868 17.4451 12.7884 20.4473 9.0794 20.4473C6.80777 20.4473 5.18888 19.4023 4.30054 18.1497V20.2128H0.644531V0.887695H4.30054V7.86038C5.18888 6.52896 6.88558 5.50986 9.0794 5.50986ZM8.11326 8.7217C6.15503 8.7217 4.30054 10.2368 4.30054 12.9786C4.30054 15.7203 6.15503 17.2355 8.11326 17.2355C10.0715 17.2355 11.9519 15.6684 11.9519 12.9267C11.9519 10.185 10.0974 8.7217 8.11326 8.7217Z" fill="#001781" />
      <path d="M24.0176 5.50977C26.3421 5.50977 27.9351 6.60668 28.7964 7.80734V5.74428H32.4784V20.2116H28.7964V18.0967C27.934 19.3503 26.2892 20.4472 23.9916 20.4472C20.3356 20.4472 17.4102 17.444 17.4102 12.9266C17.4102 8.40929 20.3356 5.50977 24.0176 5.50977ZM24.9578 8.72161C22.9995 8.72161 21.1451 10.1838 21.1451 12.9266C21.1451 15.6694 22.9995 17.2354 24.9578 17.2354C26.916 17.2354 28.7964 15.7202 28.7964 12.9785C28.7964 10.2368 26.9679 8.72161 24.9578 8.72161Z" fill="#001781" />
      <path d="M56.2959 12.2209C56.2959 9.92329 55.0164 8.72155 53.0841 8.72155C51.1518 8.72155 49.8463 9.92329 49.8463 12.2209V20.2116H46.1903V12.2209C46.1903 9.92329 44.9107 8.72155 42.9784 8.72155C41.0461 8.72155 39.7136 9.92329 39.7136 12.2209V20.2116H36.0576V5.74422H39.7136V7.49388C40.6279 6.29214 42.1679 5.53564 43.9705 5.53564C46.2162 5.53564 48.0707 6.50179 49.0628 8.3044C50.003 6.65957 51.9093 5.53564 54.0243 5.53564C57.4977 5.53564 59.9519 7.72947 59.9519 11.7248V20.2127H56.2959V12.2219V12.2209Z" fill="#001781" />
      <path d="M71.8597 5.50986C75.5676 5.50986 78.4671 8.4083 78.4671 12.9267C78.4671 17.4451 75.5686 20.4473 71.8597 20.4473C69.588 20.4473 67.9691 19.4023 67.0808 18.1497V20.2128H63.4248V0.887695H67.0808V7.86038C67.9691 6.52896 69.6658 5.50986 71.8597 5.50986ZM70.8935 8.7217C68.9353 8.7217 67.0808 10.2368 67.0808 12.9786C67.0808 15.7203 68.9353 17.2355 70.8935 17.2355C72.8518 17.2355 74.7322 15.6684 74.7322 12.9267C74.7322 10.185 72.8777 8.7217 70.8935 8.7217Z" fill="#001781" />
      <path d="M87.5548 20.4472C83.3768 20.4472 80.2168 17.5228 80.2168 12.9785C80.2168 8.43414 83.4816 5.50977 87.6596 5.50977C91.8376 5.50977 95.1024 8.40821 95.1024 12.9785C95.1024 17.5488 91.7598 20.4472 87.5548 20.4472ZM87.5548 17.2613C89.4871 17.2613 91.3415 15.851 91.3415 12.9785C91.3415 10.106 89.54 8.69567 87.6066 8.69567C85.6733 8.69567 83.9247 10.08 83.9247 12.9785C83.9247 15.8769 85.5965 17.2613 87.5548 17.2613Z" fill="#001781" />
      <path d="M104.216 20.4472C100.038 20.4472 96.8779 17.5228 96.8779 12.9785C96.8779 8.43414 100.143 5.50977 104.321 5.50977C108.499 5.50977 111.764 8.40821 111.764 12.9785C111.764 17.5488 108.421 20.4472 104.216 20.4472ZM104.216 17.2613C106.148 17.2613 108.003 15.851 108.003 12.9785C108.003 10.106 106.201 8.69567 104.268 8.69567C102.334 8.69567 100.586 10.08 100.586 12.9785C100.586 15.8769 102.258 17.2613 104.216 17.2613Z" fill="#001781" />
    </svg>


  )
}
