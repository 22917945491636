import React, { useEffect, useState } from 'react'

import { locationService } from './locationService'
import ChooseRegionModal from '../../components/choose-region-modal/ChooseRegionModal'
import { AUSTRALIA_COUNTRY_CODE } from '../../constants/geolocation'
import { UserLocationProvider } from '../../context/user-location-context'

function LocationProvider({ children }) {
  const [userLocationState, setUserLocationState] = useState(
    AUSTRALIA_COUNTRY_CODE
  )

  useEffect(() => {
    const fetchData = async () => {
      const data = await locationService.getLocationIsoCode()

      setUserLocationState(data)
    }

    fetchData()
  }, [])

  return (
    <UserLocationProvider value={userLocationState}>
      {children}
      <ChooseRegionModal />
    </UserLocationProvider>
  )
}

export default LocationProvider
