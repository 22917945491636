import React from 'react'

export function SvgPause({ width = 24, height = 24, className }) {
  return <svg
    {...{ width, height, className }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 5h4v14H6V5Zm8 0h4v14h-4V5Z" fill="#0047BB" />
  </svg>
}
