import { useLocation } from '@reach/router'
import { useContext, useEffect, useState } from 'react'

import ROUTES from '../../constants/routes'
import { RegionContext } from '../../context/region-context'

const UsAuPath = [
  ROUTES.TEAM,
  ROUTES.PRIVACY_COLLECTION_STATEMENT,
  ROUTES.PROMOTIONS,
  ROUTES.DOWNLOAD,
  ROUTES.EARLY_ACCESS,
  ROUTES.WAITLIST,
]

const nonUsPath = [
  ROUTES.SMSF,
  ROUTES.RISK_DISCLOSURE_STATEMENT,
  ROUTES.BAM_REWARDS,
  ROUTES.GOLD_SILVER,
  '/blog/smsf/',
]

export const useRegionPropperPath = () => {
  const region = useContext(RegionContext)
  const { pathname } = useLocation()
  const [path, setPath] = useState(false)

  const setRedirectionPath = () => {
    if (region.isUSRegion) {
      setPath(pathname.replace('/us', ''))
      return
    }

    if (Number(pathname.split('/')[1])) {
      setPath(pathname)
      return
    }

    if (nonUsPath.includes(pathname)) {
      setPath('/')
    } else if (
      UsAuPath.includes(pathname) ||
      (pathname.includes('blog') && pathname.length > 18)
    ) {
      setPath(pathname)
    } else {
      setPath(`/us${pathname}`)
    }
  }

  useEffect(() => {
    setRedirectionPath()
  }, [pathname, region.isUSRegion])

  return {
    path,
  }
}
