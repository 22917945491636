import React from 'react'

export function SvgChevron({
  width = 14,
  height = 14,
  className,
  isReversed,
}) {
  return <svg
    {...{ width, height, className }}
    style={isReversed && { transform: 'rotate(180deg)' }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 .71a.996.996 0 000 1.41L4.88 6 1 9.88a.996.996 0 101.41 1.41L7 6.7a.996.996 0 000-1.41L2.41.7C2.03.32 1.39.32 1 .71z" />
  </svg>
}
