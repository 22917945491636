import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'


import * as styles from './BurgerButton.module.scss'
import { SvgHamburger } from '../../../svg-icons'

function BurgerButton({ className, ...rest }) {
  return (
    <button className={classNames(styles.burger, className)} {...rest}>
      <SvgHamburger />
    </button>
  )
}

BurgerButton.propTypes = {
  className: PropTypes.string,
}

export default BurgerButton
