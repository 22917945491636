import React, { useState, useMemo, useContext } from 'react'

import DesktopAppDownload from '../../components/desktop-app-download/DesktopAppDownload'

const initialState = {
  isAppDownloadOpenState: false,
  closeAppDownloadOverlay: () => {},
  openAppDownloadOverlay: () => {},
  toggleDownloadScreenAppeared: () => {},
}

export const DesktopOverlayContext = React.createContext(initialState)

export const useDesktopOverlayContext = () => {
  const desktopOverlayContext = useContext(DesktopOverlayContext);
  if (!desktopOverlayContext) {
    throw new Error('Error');
  }
  return desktopOverlayContext;
};


export function DesktopOverlayContextProvider({ children }) {
  const [isAppDownloadOpenState, setIsAppDownloadOpenState] = useState(false)

  const closeAppDownloadOverlay = () => {
    setIsAppDownloadOpenState(false)
  }

  const openAppDownloadOverlay = () => {
    setIsAppDownloadOpenState(true)
  }

  const toggleDownloadScreenAppeared = () =>
    setIsAppDownloadOpenState(prevState => !prevState)

  const wrapped = useMemo(() => ({
      isAppDownloadOpenState,
      closeAppDownloadOverlay,
      openAppDownloadOverlay,
      toggleDownloadScreenAppeared,
    }
  ), []);

  return (
    <DesktopOverlayContext.Provider
      value={wrapped}
    >
      {children}
      {isAppDownloadOpenState && (
        <DesktopAppDownload onCloseClick={toggleDownloadScreenAppeared} />
      )}
    </DesktopOverlayContext.Provider>
  )
}
