// extracted by mini-css-extract-plugin
export var arrow = "MobileNav-module--arrow--f5909";
export var bambooButtonWrapper = "MobileNav-module--bambooButtonWrapper--878a9";
export var body = "MobileNav-module--body--ec173";
export var bold = "MobileNav-module--bold--be96a";
export var btn = "MobileNav-module--btn--c5e6b";
export var country = "MobileNav-module--country--a06fe";
export var countryText = "MobileNav-module--countryText--3df6c";
export var dropdown = "MobileNav-module--dropdown--a1a28";
export var footer = "MobileNav-module--footer--7685a";
export var header = "MobileNav-module--header--69e9b";
export var logo = "MobileNav-module--logo--d8b4f";
export var mobileNav = "MobileNav-module--mobileNav--945b7";
export var navigation = "MobileNav-module--navigation--00a60";
export var navigationList = "MobileNav-module--navigationList--4086b";
export var navigationListItem = "MobileNav-module--navigationListItem--c35fd";
export var open = "MobileNav-module--open--5996e";
export var openSubMenu = "MobileNav-module--openSubMenu--a3b63";
export var socialLinks = "MobileNav-module--socialLinks--bf670";
export var subMenu = "MobileNav-module--subMenu--50dd4";
export var subMenuList = "MobileNav-module--subMenuList--3e3fd";
export var sublinksList = "MobileNav-module--sublinksList--c6193";
export var triger = "MobileNav-module--triger--c82e1";