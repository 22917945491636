import React from 'react'

const initialState = { state: 'AU' }

const UserLocationContext = React.createContext(initialState)

export const {
  Provider: UserLocationProvider,
  Consumer: UserLocationConsumer,
} = UserLocationContext
export default UserLocationContext
