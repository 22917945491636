import classNames from 'classnames'
import { Link } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'

import * as styles from './WaitingListBanner.module.scss'
import { VIEW_CONTENT } from '../../../constants/facebookPixel'
import {
  AUSTRALIA_COUNTRY_CODE,
  USA_COUNTRY_CODE,
} from '../../../constants/geolocation'
import ROUTES from '../../../constants/routes'
import UserLocationContext from '../../../context/user-location-context'
import { facebookPixelStandartEvent } from '../../../services/facebook-pixel'
import Button from '../../ui/buttons/button/Button'

function WaitingListBanner() {
  const userCountryCode = useContext(UserLocationContext)
  const [isAustralianLocation, setIsAustralianLocation] = useState(true)
  const [minimizedState, setMinimizedState] = useState(false)

  const toggleBannerState = () => {
    setMinimizedState(!minimizedState)
  }

  useEffect(() => {
    if (userCountryCode) {
      setIsAustralianLocation(userCountryCode === AUSTRALIA_COUNTRY_CODE)
    }

    if (userCountryCode === USA_COUNTRY_CODE) {
      facebookPixelStandartEvent(VIEW_CONTENT, {
        content_name: 'Waiting list banner',
      })
    }
  }, [userCountryCode])

  return (
    !isAustralianLocation && (
      <div
        className={classNames(
          styles.banner,
          minimizedState && styles.minimized
        )}
      >
        <Button
          className={styles.minimizeToggleBtn}
          onClick={toggleBannerState}
        >
          Join Waitlist
        </Button>

        <div className={styles.wrapper}>
          <header className={styles.header}>
            <h3 className={styles.title}>
              We are not yet available in your country.
            </h3>
            <p className={styles.text}>
              Join the growing waitlist to effortless investing. Coming soon to
              🇺🇸 USA.
            </p>
            <Link
              className={classNames(styles.link, styles.small)}
              to={ROUTES.WAITLIST}
            >
              Join Waitlist
            </Link>
          </header>
        </div>
      </div>
    )
  )
}

export default WaitingListBanner
