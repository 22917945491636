import { useLocation } from '@reach/router'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { isDesktop } from 'react-device-detect'

import * as styles from './GetBambooButton.module.scss'
import { GET_APP_PRESS } from '../../../../../constants/analytics'
import { GET_APP_PRESS_FB } from '../../../../../constants/facebookPixel'
import ROUTES from '../../../../../constants/routes'
import { useDesktopOverlayContext } from '../../../../../context/desktop-overlay-context/DesktopOverlayContext'
import { RegionContext } from '../../../../../context/region-context'
import { facebookPixelCustomEvent } from '../../../../../services/facebook-pixel'
import { logEvent } from '../../../../../services/firebase/analytics'
import { gtag_report_conversion } from '../../../../../services/gtag-anlytics'
import DesktopAppDownload from '../../../../desktop-app-download/DesktopAppDownload'
import Button from '../../button/Button'
import LinkButton from '../../link-button/LinkButton'

const DEFAULT_BUTTON_LINK = 'https://app.getbamboo.io/invest'
const US_BUTTON_LINK = 'https://getbamboo.typeform.com/to/fspr14Qu'

function GetBambooButton({ small, ...rest }) {
  const region = useContext(RegionContext)
  const { pathname } = useLocation()
  const { toggleDownloadScreenAppeared } = useDesktopOverlayContext();

  const [isAppDownloadOpenState, setIsAppDownloadOpenState] = useState(false)
  const [buttonLink, setButtonLink] = useState(DEFAULT_BUTTON_LINK)

  const handleMobileGetAppClick = () => {
    if (pathname === ROUTES.DOWNLOAD) {
      gtag_report_conversion()
    }
    logEvent(GET_APP_PRESS)
    facebookPixelCustomEvent(GET_APP_PRESS_FB)
  }

  useEffect(() => {
    switch (pathname) {
      case ROUTES.DOWNLOAD:
        setButtonLink('https://getbamboo.onelink.me/a5Pp/GetBambooToday')
        break
      case ROUTES.DOWNLOAD_SNAP:
        setButtonLink('https://getbamboo.onelink.me/a5Pp/getbamboo')
        break
      case ROUTES.DOWNLOAD_FACEBOOK:
        setButtonLink('https://getbamboo.onelink.me/a5Pp/GetBamboo')
        break

      default:
        break
    }
  }, [pathname])

  return (
    <div {...rest}>
      {isDesktop && !region.isUSRegion ? (
        <>
          {isAppDownloadOpenState && (
            <DesktopAppDownload onCloseClick={toggleDownloadScreenAppeared} />
          )}

          <Button {...{ small }} className={styles.linkButton} onClick={toggleDownloadScreenAppeared}>
            Get Started
          </Button>
        </>
      ) : (
        <LinkButton
          href={region.isUSRegion ? US_BUTTON_LINK : buttonLink}
          onClick={handleMobileGetAppClick}
          title="Download Bamboo investment App"
          className={styles.linkButton}
          isTargetBlank={region.isUSRegion}
          {...{ small }}
        >
          Get Started
        </LinkButton>
      )}
    </div>
  )
}

GetBambooButton.propTypes = {
  small: PropTypes.bool,
}

export default GetBambooButton
