import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './LinkButton.module.scss'

function LinkButton({
  children,
  className,
  small,
  outline,
  shadowed,
  isTargetBlank,
  ...rest
}) {
  return (
    <a
      className={classNames(
        styles.btn,
        small && styles.small,
        outline && styles.outline,
        shadowed && styles.shadowed,
        className
      )}
      target={isTargetBlank ? '_blank' : '_self'}
      rel={isTargetBlank ? 'noopener noreferrer' : ''}
      {...rest}
    >
      {children}
    </a>
  )
}

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  small: PropTypes.bool,
  outline: PropTypes.bool,
  shadowed: PropTypes.bool,
  isTargetBlank: PropTypes.bool,
}

export default LinkButton
