import React, { useState } from 'react'

const initialState = { isUSRegion: false, setActiveUSRegion: () => {} }

export const RegionContext = React.createContext(initialState)

export function RegionProvider({ children }) {
  const [isUSRegion, setIsUSRegion] = useState(false)

  const setActiveUSRegion = region => {
    setIsUSRegion(region)
  }

  return (
    <RegionContext.Provider
      value={{
        isUSRegion,
        setActiveUSRegion,
      }}
    >
      {children}
    </RegionContext.Provider>
  )
}
