import React from 'react'

export function SvgLogo({ width = 41, height = 41, className }) {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.8428 15.897C9.28656 15.3307 7.56716 16.1326 7.00088 17.6888L0.18165 36.4238C-0.384638 37.98 0.417243 39.6994 1.97345 40.2657C2.31171 40.3889 2.65754 40.4473 2.99796 40.4473C4.22239 40.4473 5.37226 39.6908 5.81535 38.4739L12.6346 19.7389C13.2009 18.1827 12.399 16.4633 10.8428 15.897Z" fill="#001781" />
      <path d="M24.8114 8.40339C23.5815 7.95598 22.248 8.36448 21.4645 9.32415C20.5448 6.31007 12.0256 0.447266 9.36816 0.447266C9.36816 2.58381 14.7846 6.88717 18.6157 8.82702C16.8769 8.60764 15.0472 8.93617 13.5829 9.81479C15.7497 11.1149 18.7162 11.2111 21.003 10.1066C20.9911 10.1358 20.9792 10.1649 20.9684 10.1952L11.4215 36.4238C10.8552 37.98 11.6571 39.6994 13.2133 40.2657C13.5516 40.3889 13.8974 40.4473 14.2378 40.4473C15.4622 40.4473 16.6121 39.6908 17.0552 38.4739L26.6021 12.2453C27.1684 10.6891 26.3665 8.96968 24.8103 8.40339H24.8114Z" fill="#001781" />
      <path d="M38.7806 0.908701C37.2254 0.342413 35.505 1.14429 34.9387 2.7005L22.6641 36.4238C22.0978 37.98 22.8997 39.6994 24.4559 40.2657C24.7941 40.3889 25.14 40.4472 25.4804 40.4472C26.7048 40.4472 27.8547 39.6908 28.2978 38.4739L40.5724 4.75059C41.1387 3.19438 40.3368 1.47499 38.7806 0.908701Z" fill="#001781" />
    </svg>

  )
}
