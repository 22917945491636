import classNames from 'classnames'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React, { useState, useContext } from 'react'

import * as styles from './CountryDropdown.module.scss'
import {
  AUSTRALIA_COUNTRY_CODE,
  USA_COUNTRY_CODE,
} from '../../constants/geolocation'
import { RegionContext } from '../../context/region-context'
import { useRegionPropperPath } from '../../helpers/hooks/useRegionPropperPath'
import { SvgChevronDown } from '../ui/svg-icons'

function CountryDropdown({ className }) {
  const { path } = useRegionPropperPath()
  const region = useContext(RegionContext)
  const [isDropdownOpenState, setDropdownState] = useState(false)

  const toggleDropdown = () => {
    setDropdownState(isDropdownOpenState => !isDropdownOpenState)
  }

  const toggleRegion = () => {
    sessionStorage?.setItem('isRegionSelected', JSON.stringify(true))
    localStorage?.setItem(
      'isUSRegionSelected',
      JSON.stringify(!region.isUSRegion)
    )

    region?.setActiveUSRegion(state => !state)

    toggleDropdown()
  }

  const US_Flag = (
    <StaticImage
      src="../../assets/images/us_flag.png"
      alt="US country flag"
      width={24}
      height={24}
      className={styles.flag}
      objectPosition="0% 0%"
    />
  )
  const AU_Flag = (
    <StaticImage
      src="../../assets/images/au_flag.webp"
      alt="AU country flag"
      width={24}
      className={styles.flag}
      height={24}
      objectPosition="0% 0%"
    />
  )

  return (
    <div className={classNames(styles.regionSwitcher, className)}>
      <button className={styles.toggleBtn} onClick={toggleDropdown}>
        {region?.isUSRegion ? US_Flag : AU_Flag}
        {region?.isUSRegion ? USA_COUNTRY_CODE : AUSTRALIA_COUNTRY_CODE}
        <SvgChevronDown className={styles.chevron}/>
      </button>
      <div
        className={classNames(
          styles.dropdown,
          isDropdownOpenState && styles.open
        )}
      >
        <div>
          {path && (
            <Link
              to={path}
              onClick={() => toggleRegion()}
              className={styles.countryItem}
            >
              {region?.isUSRegion ? AU_Flag : US_Flag}
              <span>
                {region?.isUSRegion ? AUSTRALIA_COUNTRY_CODE : USA_COUNTRY_CODE}
              </span>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

CountryDropdown.propTypes = {
  className: PropTypes.string,
}

export default CountryDropdown
