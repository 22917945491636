import React from 'react'

export function SvgYouTube({ width = 32, height = 33, className }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3511_42010)">
        <path d="M27.4982 10.6327C27.2227 9.59358 26.4086 8.7754 25.3764 8.49722C23.5055 7.99268 16 7.99268 16 7.99268C16 7.99268 8.49455 7.99268 6.62364 8.49722C5.59136 8.7754 4.77727 9.59358 4.50182 10.6327C4 12.5172 4 16.4472 4 16.4472C4 16.4472 4 20.3772 4.50182 22.2618C4.77727 23.3009 5.59136 24.119 6.62364 24.3972C8.49455 24.9018 16 24.9018 16 24.9018C16 24.9018 23.5055 24.9018 25.3764 24.3972C26.4086 24.119 27.2227 23.3009 27.4982 22.2618C28 20.3772 28 16.4472 28 16.4472C28 16.4472 28 12.5172 27.4982 10.6327ZM13.5455 20.0159V12.8786L19.8182 16.4472L13.5455 20.0159Z" fill="#0047BB" />
      </g>
      <defs>
        <clipPath id="clip0_3511_42010">
          <rect width="24" height="16.9091" fill="white" transform="translate(4 7.99268)" />
        </clipPath>
      </defs>
    </svg>

  )
}
