import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import * as styles from './CountryButton.module.scss'
import {
  AUSTRALIA_COUNTRY_CODE,
  USA_COUNTRY_CODE,
} from '../../constants/geolocation'
import { RegionContext } from '../../context/region-context'

function CountryButton({ className }) {
  const region = useContext(RegionContext)

  const toggleRegion = () => {
    sessionStorage?.setItem('isRegionSelected', JSON.stringify(true))
    localStorage?.setItem(
      'isUSRegionSelected',
      JSON.stringify(!region.isUSRegion)
    )

    region?.setActiveUSRegion(state => !state)
  }

  const US_Flag = (
    <StaticImage
      src="../../assets/images/us_flag.png"
      alt="US country flag"
      width={24}
      height={24}
      className={styles.flag}
    />
  )
  const AU_Flag = (
    <StaticImage
      src="../../assets/images/au_flag.webp"
      alt="AU country flag"
      width={24}
      className={styles.flag}
      height={24}
    />
  )

  return (
    <div className={classNames(styles.regionSwitcher, className)}>
      <button className={styles.toggleBtn} onClick={toggleRegion}>
        {region?.isUSRegion ? AU_Flag : US_Flag}
        {`Change to ${region?.isUSRegion ? AUSTRALIA_COUNTRY_CODE : USA_COUNTRY_CODE}`}
      </button>
    </div>
  )
}

CountryButton.propTypes = {
  className: PropTypes.string,
}

export default CountryButton
