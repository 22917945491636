import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-modal'

import * as styles from './ChooseRegionModal.module.scss'
import { USA_COUNTRY_CODE } from '../../constants/geolocation'
import ROUTES from '../../constants/routes'
import { RegionContext } from '../../context/region-context'
import UserLocationContext from '../../context/user-location-context'
import CloseButton from '../ui/buttons/custom/close-button/CloseButton'

Modal.setAppElement('#___gatsby')

const isBrowser = typeof window !== 'undefined'

function ChooseRegionModal() {
  const region = useContext(RegionContext)
  const userGeolocation = useContext(UserLocationContext)

  const [modalIsOpen, setIsOpen] = useState(false)

  const openModal = () => {
    setTimeout(() => setIsOpen(true), 10000)
  }
  const closeModal = () => {
    setIsOpen(false)
  }

  const selectRegion = selectedRegion => {
    localStorage?.setItem(
      'isUSRegionSelected',
      JSON.stringify(selectedRegion === USA_COUNTRY_CODE)
    )
    region.setActiveUSRegion(selectedRegion === USA_COUNTRY_CODE)
    sessionStorage.setItem('isRegionSelected', JSON.stringify(true))

    setIsOpen(false)
  }

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden'
    }
    return () => (document.body.style.overflow = 'unset')
  }, [])

  useEffect(() => {
    let isMounted = true
    let isRegionSelected

    if (isBrowser) {
      isRegionSelected = JSON.parse(sessionStorage?.getItem('isRegionSelected'))
    }

    if (
      ((userGeolocation === USA_COUNTRY_CODE && !region.isUSRegion) ||
        (userGeolocation !== USA_COUNTRY_CODE && region.isUSRegion)) &&
      !isRegionSelected
    ) {
      if (isMounted) openModal()
    }

    return () => {
      isMounted = false
    }
  }, [region.isUSRegion, userGeolocation])

  const US_Flag = (
    <StaticImage
      src="../../assets/images/us_flag.webp"
      alt="US country flag"
      width={120}
      quality={100}
    />
  )
  const AU_Flag = (
    <StaticImage
      src="../../assets/images/au_flag.webp"
      alt="AU country flag"
      width={120}
      quality={100}
    />
  )

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.modal}
        overlayClassName={styles.overlay}
        contentLabel="Example Modal"
      >
        <div className={styles.contentWrapp}>
          <CloseButton className={styles.closeBtn} onClick={closeModal} />
          <h2 className={styles.title}>ARE YOU ON THE RIGHT BAMBOO SITE?</h2>
          <p className={styles.text}>
            Ensure you’re getting the correct info about how to effortlessly
            invest into crypto in your region with Bamboo, by selecting the
            correct country you’ll be investing from.
          </p>

          <ul className={styles.flagsList}>
            <li>
              <Link to={ROUTES.US_HOME} onClick={() => selectRegion('US')}>
                {US_Flag}
                <p>United States</p>
              </Link>
            </li>

            <li>
              <Link to={ROUTES.HOME} onClick={selectRegion}>
                {AU_Flag}
                <p>Australia</p>
              </Link>
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  )
}

export default ChooseRegionModal
