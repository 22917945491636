export const ROUTES = {
  HOME: '/',
  ABOUT: '/about/',
  BLOG: '/blog/',
  CONTACTS: '/contact/',
  TEAM: '/team/',
  FEATURES: '/features/',
  FEES: '/fees/',
  SUPPORT: '/support/',
  PRIVACY_POLICY: '/legal/privacy-policy/',
  PRIVACY_COLLECTION_STATEMENT: '/legal/privacy-collection-statement/',
  US_PRIVACY_COLLECTION_STATEMENT: '/us/legal/privacy-collection-statement/',
  EVOLVE_AGREEMENT: '/legal/evolve-agreement/',
  TERMS: '/legal/terms/',
  PROMOTIONS: '/legal/promotions/',
  US_PROMOTIONS: '/us/legal/promotions/',
  RISK_DISCLOSURE_STATEMENT: '/legal/risk-disclosure-statement/',
  SMSF: '/smsf/',
  SECURITY: '/security/',
  FAQ: '/faq/',
  WAITLIST: '/waitlist/',
  ROUND_UPS: '/features/round-ups/',
  TOP_UPS: '/features/top-ups/',
  PORTFOLIO: '/features/portfolio/',
  REBALANCE: '/features/rebalance/',
  DOLLAR_COST_AVERAGE: '/features/dollar-cost-average/',
  BAM_REWARDS: '/features/bam-rewards/',
  DISCLAIMER: '/disclaimer/',
  BITCOIN_ETHEREUM: '/bitcoin-ethereum/',
  GOLD_SILVER: '/gold-silver/',
  CRYPTO_CURIOUS: '/crypto-curious-podcast/',
  PARTNERS: '/partners/',
  REFERRAL: '/referral/',
  COMMUNITY: '/community/',
  DOWNLOAD: '/download/',
  DOWNLOAD_SNAP: '/download/snap/',
  DOWNLOAD_FACEBOOK: '/download/facebook/',
  EARLY_ACCESS: '/early-access/',
  DCA_BLOG: '/blog/the-art-of-dollar-cost-averaging/',
  US_HOME: '/us/',
  US_ABOUT: '/us/about/',
  US_BLOG: '/us/blog/',
  US_FEATURES: '/us/features/',
  US_SUPPORT: '/us/support/',
  US_PRIVACY_POLICY: '/us/legal/privacy-policy/',
  US_TERMS: '/us/legal/terms/',
  US_SECURITY: '/us/security/',
  US_FAQ: '/us/faq/',
  US_ROUND_UPS: '/us/features/round-ups/',
  US_TOP_UPS: '/us/features/top-ups/',
  US_PORTFOLIO: '/us/features/portfolio/',
  US_REBALANCE: '/us/features/rebalance/',
  US_DOLLAR_COST_AVERAGE: '/us/features/dollar-cost-average/',
  US_BITCOIN_ETHEREUM: '/us/bitcoin-ethereum/',
  US_CRYPTO_CURIOUS: '/us/crypto-curious-podcast/',
  US_PARTNERS: '/us/partners/',
  US_COMMUNITY: '/us/community/',
  US_ADDITIONAL_REQUIRED_DISCLOSURES: '/us/legal/additional-required-disclosures/',
}

export default ROUTES
