export const SITE_URL = 'https://www.getbamboo.io'
export const DEFAULT_SITE_NAME = 'Bamboo App'
export const DEFAULT_SITE_CONTENT_TYPE = 'website'
export const DEFAULT_TITLE =
  'Crypto Micro-Investing | Gold & Crypto Round-Up App - Bamboo'
export const TITLE_TEMPLATE =
  '%s - microinvestments platform for purchase digital assets, Bitcoin cryptocurrency and Gold.'
export const DEFAULT_DESCRIPTION =
  "Bamboo is a micro-investing app allowing users to effortlessly invest in commodities & cryptocurrency. Invest today's change in tomorrow. Get started today."
export const DEFAULT_KEYWORDS =
  'crypto micro investing, crypto round up app, bamboo, blockchain, gold, silver, precious metals, bitcoin, ethereum, digital assets, litecoin, crypto, buy, invest, invest today, micro investment, microinvestment, micro-investment, solvency protocol, proof of solvency, spare change, budget'
export const DEFAULT_IMAGE =
  'https://www.getbamboo.io/assets/Bamboo_SocialShare2.jpg'
export const DEFAULT_IMAGE_FOLDER = 'https://www.getbamboo.io/assets/'
export const TWITTER_USERNAME = ''
export const BAMBOO_ORGANIZATION_NAME = 'Bamboo'
export const BAMBOO_ORGANIZATION_STRUCTURED_DATA_DESCRIPTION =
  'Microinvestments platform for purchase digital assets, Bitcoin cryptocurrency and Gold.'
export const BAMBOO_TOWN_LOCATION = 'Peppermint Grove'
export const BAMBOO_POSTAL_CODE = '6000'
export const BAMBOO_STREET_ADDRESS = '5 / 10 Johnston St'
export const BAMBOO_ADDRESS_COUNTRY = 'Australia'
export const BAMBOO_SUPPORT_EMAIL_TO = 'mailto:support@getbamboo.io'
export const BAMBOO_LOGO_SRC = 'https://www.getbamboo.io/assets/bamboologo.png'

export const BAMBOO_FACEBOOK_URL = 'https://www.facebook.com/getbambooapp'
export const BAMBOO_TWITTER_URL = 'https://twitter.com/getbambooapp'
export const BAMBOO_LINKEDIN_URL =
  'https://www.linkedin.com/company/getbambooapp'
export const BAMBOO_INSTAGRAM_URL = 'https://www.instagram.com/getbambooapp/'
export const BAMBOO_YOUTUBE_URL =
  'https://www.youtube.com/channel/UCkg81C-orU0Lj5YjEDfSJYA'
export const BAMBOO_SPOTIFY_URL = ''
export const BAMBOO_MEDIUM_URL = 'https://medium.com/getbambooapp'
export const BAMBOO_SUPPORT_EMAIL = 'support@getbamboo.io'
export const FINTECH_AUS_URL = 'https://www.fintechaustralia.org.au/'
export const BLOCKCHAIN_AUS_URL = 'https://blockchainaustralia.org/'