import classNames from 'classnames'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import * as styles from './BlogHeader.module.scss'
import ROUTES from '../../constants/routes'
import { RegionContext } from '../../context/region-context'


const blogCategories = [
  {
    name: 'All',
    path: ROUTES.BLOG,
    regexp: /\/blog\/(?![a-z])|((\d\/)?(^$))/,
  },
  {
    name: 'Crypto',
    path: `${ROUTES.BLOG}crypto/`,
    regexp: 'crypto',
  },
  {
    name: 'Investing',
    path: `${ROUTES.BLOG}investing/`,
    regexp: 'investing',
  },
  {
    name: 'SMSF',
    path: `${ROUTES.BLOG}smsf/`,
    regexp: 'smsf',
  },
  {
    name: 'Culture',
    path: `${ROUTES.BLOG}culture/`,
    regexp: 'culture',
  },
  {
    name: 'Product',
    path: `${ROUTES.BLOG}product/`,
    regexp: 'product',
  },
]
const usBlogCategories = [
  {
    name: 'All',
    path: ROUTES.US_BLOG,
    regexp: /\/us\/blog\/(?![a-z])|((\d\/)?(^$))/,
  },
  {
    name: 'Crypto',
    path: `${ROUTES.US_BLOG}crypto/`,
    regexp: 'crypto',
  },
  {
    name: 'Investing',
    path: `${ROUTES.US_BLOG}investing/`,
    regexp: 'investing',
  },
  {
    name: 'Culture',
    path: `${ROUTES.US_BLOG}culture/`,
    regexp: 'culture',
  },
  {
    name: 'Product',
    path: `${ROUTES.US_BLOG}product/`,
    regexp: 'product',
  },
]

function BlogHeader({ location, ...rest }) {
  const region = useContext(RegionContext)

  const categories = region.isUSRegion ? usBlogCategories : blogCategories

  return (
    <div className={styles.header} {...rest}>
      <nav className={styles.navigation}>
        <ul className={styles.navList}>
          {categories.map(({ name, path, regexp }, index) => {
            const isActive = new RegExp(regexp).test(location)

            return (
              <li key={index}>
                <Link
                  className={classNames(
                    styles.navItem,
                    isActive && styles.active
                  )}
                  to={path}
                >
                  {name}
                </Link>
              </li>
            )
          })}
        </ul>
      </nav>
    </div>
  )
}

BlogHeader.propTypes = {
  location: PropTypes.string.isRequired,
}

export default BlogHeader
