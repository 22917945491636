import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import * as styles from './MobileMenu.module.scss'
import MobileNav from '../mobile-nav/MobileNav'
import BurgerButton from '../ui/buttons/custom/burger-button/BurgerButton'


function MobileMenu({ className, ...rest }) {
  const [openState, setOpenState] = useState(false)

  const toggleOpenState = () => setOpenState(prevState => !prevState)
  return (
    <div className={classNames(styles.mobileMenu, className)} {...rest}>
      <BurgerButton onClick={toggleOpenState} aria-label="Open mobile menu" />

      <MobileNav open={openState} onCloseClick={toggleOpenState} />
    </div>
  )
}

MobileMenu.propTypes = {
  className: PropTypes.string,
}

export default MobileMenu
