export const facebookPixelCustomEvent = (eventName, eventParams = null) => {
  if (typeof window !== 'undefined') {
    if (window.fbq != null) {
      window.fbq('trackCustom', eventName, eventParams)
    }
  }
}

export const facebookPixelStandartEvent = (eventName, eventParams = null) => {
  if (typeof window !== 'undefined') {
    if (window.fbq != null) {
      window.fbq('track', eventName, eventParams)
    }
  }
}
