import React from 'react'

export function SvgStars({ width = 120, height = 25, className }) {
  return <svg {...{ width, height, className }} xmlns="http://www.w3.org/2000/svg">
    <path
      d="m12 3.755 3.06 5.302L21 10.355l-4.05 4.575.612 6.105L12 18.56l-5.562 2.474.612-6.104L3 10.355l5.94-1.298L12 3.755ZM36 3.755l3.06 5.302L45 10.355l-4.05 4.575.612 6.105L36 18.56l-5.562 2.474.612-6.104L27 10.355l5.94-1.298L36 3.755ZM60 3.755l3.06 5.302L69 10.355l-4.05 4.575.612 6.105L60 18.56l-5.562 2.474.612-6.104L51 10.355l5.94-1.298L60 3.755ZM84 3.755l3.06 5.302L93 10.355l-4.05 4.575.612 6.105L84 18.56l-5.562 2.474.612-6.104L75 10.355l5.94-1.298L84 3.755ZM108 3.755l3.059 5.302L117 10.355l-4.05 4.575.612 6.105L108 18.56l-5.562 2.474.612-6.104L99 10.355l5.941-1.298L108 3.755Z"
      fill="#FFBF3F"
    />
  </svg>
}
