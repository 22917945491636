import React from 'react'

import BootstrapFirebase from './src/services/firebase/botstrapFirebase'
import LocationProvider from './src/services/geolocation/locationProvider'
import { RegionProvider } from './src/context/region-context'

const APP_ID = process.env.GATSBY_INTERCOM_APP_ID
const API_BASE = process.env.GATSBY_INTERCOM_API_BASE

const WrapRootElement = ({ element }) => {
  if (window?.Intercom) {
    window.Intercom('boot', {
      api_base: API_BASE,
      app_id: APP_ID,
      horizontal_padding: 50,
      vertical_padding: 50,
    })
  }

  return (
    <BootstrapFirebase>
      <RegionProvider>
        <LocationProvider>{element}</LocationProvider>
      </RegionProvider>
    </BootstrapFirebase>
  )
}

export default WrapRootElement
