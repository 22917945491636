import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

function NavLink({ children, to, external, ...rest }) {
  return external ? (
    <a href={to} target="_blank" rel="noopener noreferrer" {...rest}>
      {children}
    </a>
  ) : (
    <Link to={to} {...rest}>
      {children}
    </Link>
  )
}

NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  external: PropTypes.bool,
}

export default NavLink
