import { useLocation } from '@reach/router'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import * as styles from './StoresBlock.module.scss'
import iOSAppStore from '../../assets/new-icons/AppBadges_Apple.svg'
import AndroidAppStore from '../../assets/new-icons/AppBadges_Google.svg'
import {
  ANDROID_DOWNLOAD_PRESS,
  IOS_DOWNLOAD_PRESS,
} from '../../constants/analytics'
import {
  ANDROID_DOWNLOAD_PRESS_FB,
  IOS_DOWNLOAD_PRESS_FB,
} from '../../constants/facebookPixel'
import ROUTES from '../../constants/routes'
import { RegionContext } from '../../context/region-context'
import { facebookPixelCustomEvent } from '../../services/facebook-pixel'
import { logEvent } from '../../services/firebase/analytics'
import { gtag_report_conversion } from '../../services/gtag-anlytics'

const ANDROID_DOWNLOAD_LINK =
  'https://play.google.com/store/apps/details?id=ch.bamboocore.app&hl=en'
const IOS_DOWNLOAD_LINK =
  'https://apps.apple.com/au/app/bamboo-effortless-investment/id1386681221'
const US_BUTTON_LINK = 'https://getbamboo.typeform.com/to/fspr14Qu'

function StoresBlock({ className, onDownloadButtonClick, width = 135, height = 40 }) {
  const region = useContext(RegionContext)
  const { pathname } = useLocation()

  const handleDownloadIosClick = () => {
    if (pathname === ROUTES.DOWNLOAD) {
      gtag_report_conversion()
    }

    logEvent(IOS_DOWNLOAD_PRESS)
    facebookPixelCustomEvent(IOS_DOWNLOAD_PRESS_FB)

    if (onDownloadButtonClick) {
      onDownloadButtonClick()
    }
  }
  const handleDownloadAndroidClick = () => {
    if (pathname === ROUTES.DOWNLOAD) {
      gtag_report_conversion()
    }

    logEvent(ANDROID_DOWNLOAD_PRESS)
    facebookPixelCustomEvent(ANDROID_DOWNLOAD_PRESS_FB)

    if (onDownloadButtonClick) {
      onDownloadButtonClick()
    }
  }

  return (
    <div className={classNames(styles.wrapper, styles.mobile, className)}>
      <a
        href={region.isUSRegion ? US_BUTTON_LINK : IOS_DOWNLOAD_LINK}
        target=" _new"
        title="Download Bamboo investment iOS App"
        onClick={handleDownloadIosClick}
        className={styles.appDownloadLink}
      >
        <img
          alt="Bamboo investment iOS App"
          src={iOSAppStore}
          width={width}
          height={height}
          style={{ width, height }}
          loading="lazy"
        />
      </a>
      <a
        href={region.isUSRegion ? US_BUTTON_LINK : ANDROID_DOWNLOAD_LINK}
        target=" _new"
        title="Download Bamboo investment Android App"
        onClick={handleDownloadAndroidClick}
        className={styles.appDownloadLink}
      >
        <img
          alt="Bamboo investment Android App"
          src={AndroidAppStore}
          width={width}
          height={height}
          style={{ width, height }}
          loading="lazy"
        />
      </a>
    </div>
  )
}

StoresBlock.propTypes = {
  className: PropTypes.string,
  onDownloadButtonClick: PropTypes.func,
}

export default StoresBlock
