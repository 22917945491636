import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './Button.module.scss'

function Button({ children, className, small, outline, shadowed, ...rest }) {
  return (
    <button
      className={classNames(
        styles.btn,
        small && styles.small,
        outline && styles.outline,
        shadowed && styles.shadowed,
        className
      )}
      {...rest}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  small: PropTypes.bool,
  outline: PropTypes.bool,
}

export default Button
