import { getAnalytics } from 'firebase/analytics';

import getFirebase from './initFirebase'

export const logEvent = (event, eventParams, options) => {
  const analytics = getAnalytics(getFirebase())

  if (analytics && event) {
    analytics.logEvent(event, eventParams, options)
  }
}
