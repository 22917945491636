export const colors = {
  black: '#000000',
  white: '#ffffff',
  blue: '#0047bb',
  grey: '#63666a',
  sky: '#c6dae7',
  melon: '#ff8674',
  sugar: '#efdbb2',
  citrus: '#ffbf3f',
  plum: '#622128',
  teal: '#2cd5c4',

  // blue
  blue50: '#d7dfff',
  blue400: '#F3F6FF',
  blue800: '#002c98',
  blue900: '#001781',
  blueA700: '#0044ff',

  //citrus
  citrus50: '#fff0d1',
  citrus200: '#ffe4ae',

  //grey
  grey50: '#dadbdc',
  grey200: '#bcbec1',
  grey400: '#898c91',
  grey900: '#343637',

  //melon
  melonA100: '#fec4bd',
  melon700: '#db7267',
  melonA400: '#ff787b',

  //teal
  tealA100: '#cfffff',
  tealA700: '#2eb6a4',
  tealA400: '#EAF8F6',

  //plum
  plum50: '#e8bcc0',
  plum600: '#4e222b',

  //sky
  sky50: '#f1f6f9',
  sky200: '#e7eff5',
  sky400: '#E4F6FA',

  //sugar
  sugar50: '#fbf6ed',
  sugar200: '#f8f0df',
}
