// extracted by mini-css-extract-plugin
export var badges = "Footer-module--badges--d02ea";
export var bambooLogo = "Footer-module--bambooLogo--9dfc1";
export var bambooTextLogo = "Footer-module--bambooTextLogo--8c0ef";
export var bottom = "Footer-module--bottom--c8a48";
export var bottomLogo = "Footer-module--bottomLogo--e77c3";
export var container = "Footer-module--container--d5c55";
export var desktop = "Footer-module--desktop--a725a";
export var footer = "Footer-module--footer--8932c";
export var legalLink = "Footer-module--legalLink--00840";
export var legals = "Footer-module--legals--9505d";
export var link = "Footer-module--link--51a23";
export var logo = "Footer-module--logo--a1cb2";
export var mobile = "Footer-module--mobile--7bfcc";
export var rights = "Footer-module--rights--618d6";
export var siteMap = "Footer-module--siteMap--f1db5";
export var siteMapColumn = "Footer-module--siteMapColumn--02bec";
export var socialOptionsLink = "Footer-module--socialOptionsLink--02d83";
export var socialOptionsRow = "Footer-module--socialOptionsRow--3d464";
export var socials = "Footer-module--socials--dfc8e";
export var storesButtons = "Footer-module--storesButtons--f5c74";
export var title = "Footer-module--title--acde2";