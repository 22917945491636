import { COUNTRY_CODE_STORAGE_NAME } from '../../constants/geolocation'

const API_KEY = process.env.GATSBY_GEOAPIFI_API_KEY

export const locationService = {
  async getLocationIsoCode() {
    const handleErrors = response => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response.json()
    }

    try {
      const requestOptions = {
        method: 'GET',
      }

      const response = await fetch(
        `https://api.geoapify.com/v1/ipinfo?&apiKey=${API_KEY}`,
        requestOptions
      )
      const userlocation = await handleErrors(response)

      if (userlocation?.country?.iso_code) {
        localStorage.setItem(
          COUNTRY_CODE_STORAGE_NAME,
          userlocation.country.iso_code
        )

        return userlocation.country.iso_code
      }
    } catch (error) {
      console.log('locationService error', error)
    }
  },
}
