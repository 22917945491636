import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './Container.module.scss'

function Container({
  children,
  className,
  minWidth,
  maxWidth,
  flex,
  fluid,
  ...rest
}) {
  const inlineStyles = {
    minWidth,
    maxWidth,
  }

  return (
    <div
      style={inlineStyles}
      className={classNames(
        styles.container,
        fluid && styles.fluid,
        flex && styles.flex,
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  flex: PropTypes.bool,
  fluid: PropTypes.bool,
}

export default Container
