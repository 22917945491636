import classNames from 'classnames'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useContext, useMemo } from 'react'

import * as styles from './Footer.module.scss'
import TextLogo from '../../assets/new-icons/text_logo_small.svg'
import { CertificationNavLinks, FooterSiteNavLinks, SocialOptions, legalOptions } from '../../constants/navigation'
import ROUTES from '../../constants/routes'
import { RegionContext } from '../../context/region-context'
import Container from '../container/Container'
import StoresBlock from '../storesBlock/StoresBlock'
import StoresBlockDesktop from '../storesBlock/StoresBlockDesktop'
import NavLink from '../ui/buttons/nav-link/NavLink'
import { SvgLogo, SvgTextLogo } from '../ui/svg-icons'


function Footer({ location }) {
  const region = useContext(RegionContext)

  const siteMapLinks = FooterSiteNavLinks.map(({ title, links }, index) => {
    return (
      <div className={styles.siteMapColumn} key={`${title}_${index}`}>
        <h3 className={styles.title}>{title}</h3>
        {links?.map(
          (
            {
              url,
              us_url,
              disabledOnUS,
              disabledOnAU,
              label,
              descriptionTitle,
              isExternal,
              newFeature,
            },
            index
          ) => {
            if (!(url || us_url)) {
              return (
                <h3 className={styles.title} key={`${label}_${index}`}>
                  {label}
                </h3>
              )
            }

            if (region.isUSRegion) {
              return (
                !disabledOnUS && (
                  <NavLink
                    className={styles.link}
                    key={`${label}_${index}`}
                    to={us_url}
                    title={descriptionTitle}
                    external={isExternal}
                  >
                    {label} {newFeature && <span> NEW </span>}
                  </NavLink>
                )
              )
            }

            return (
              !disabledOnAU && (
                <NavLink
                  className={styles.link}
                  key={`${label}_${index}`}
                  to={url}
                  title={descriptionTitle}
                  external={isExternal}
                >
                  {label} {newFeature && <span> NEW </span>}
                </NavLink>
              )
            )
          }
        )}
      </div>
    )
  })

  const certificationLinks = useMemo(() => CertificationNavLinks(region?.isUSRegion).map(
    ({ title, image, url }) => (
      <a
        href={url}
        className={styles.australiaLogo}
        target="_blank"
        key={title}
        rel="noopener noreferrer"
      >
        {image}
      </a>
    )), []);

  const copyright = region?.isUSRegion
    ? 'Bamboo 1 Limited'
    : 'Bamboo 61 Pty Ltd'

  return (
    <footer className={styles.footer}>
      <Container className={styles.container} flex>
        <div className={styles.socials}>
          <div className={styles.logo}>
            <SvgLogo />
            <SvgTextLogo />
          </div>
          <div className={styles.socialOptionsRow}>
            {SocialOptions.map(option => {
              return (
                <NavLink
                  key={`${option.label}_key`}
                  to={option.url}
                  className={styles.socialOptionsLink}
                  external={option.isExternal}
                >
                  <option.icon />
                </NavLink>
              )
            })}
          </div>
        </div>

        {location !== ROUTES.DOWNLOAD &&
          location !== ROUTES.DOWNLOAD_SNAP &&
          location !== ROUTES.DOWNLOAD_FACEBOOK &&
          location !== ROUTES.EARLY_ACCESS ? (
          <>
            <div className={styles.siteMap}>
              {siteMapLinks}
            </div>
            <div className={styles.badges}>
              <StoresBlock className={classNames(styles.storesButtons, styles.mobile)} />
              <StoresBlockDesktop className={classNames(styles.storesButtons, styles.desktop)} />
              {certificationLinks}
            </div>
          </>
        ) : (
          <div className={styles.bottomLogo}>
            <img
              src={TextLogo}
              width="120px"
              height="22px"
              alt="Bamboo investments App"
            />
          </div>
        )}

        <hr className={styles.separator} />

        <div className={styles.legals}>
          {legalOptions.map(option => {
            if (region.isUSRegion && !option.disabledOnUS || !region.isUSRegion && !option.disabledOnAU) {
              return (
                <Link
                  to={option.url}
                  key={option.label}
                  className={styles.legalLink}
                >
                  {option.label}
                </Link>
              )
            }
            return null
          })}
        </div>

        <div className={styles.bottom}>
          <p className={styles.rights}>
            Copyright © {new Date().getFullYear()} {copyright}. All rights
            reserved.
          </p>
        </div>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  location: PropTypes.string,
}

export default React.memo(Footer)
