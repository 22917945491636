import PropTypes from 'prop-types'
import React, { useContext, useEffect } from 'react'

import ROUTES from '../../constants/routes'
import { DesktopOverlayContextProvider } from '../../context/desktop-overlay-context/DesktopOverlayContext'
import { RegionContext } from '../../context/region-context'
import WaitingListBanner from '../banners/waiting-list-banner/WaitingListBanner'
import Cookie from '../cookie/Cookie'
import Footer from '../footer/Footer'
import Header from '../header/Header'

import 'react-h5-audio-player/lib/styles.css'
import './layout.scss'

const isBrowser = typeof window !== 'undefined'

function Layout({ children, location, pageContext }) {
  const isWaitlistPage = location?.pathname === ROUTES.WAITLIST

  const region = useContext(RegionContext)

  useEffect(() => {
    if (pageContext?.isUSRegion) {
      region?.setActiveUSRegion(true)
    } else if (isBrowser) {
      region?.setActiveUSRegion(
        JSON.parse(localStorage?.getItem('isUSRegionSelected'))
      )
    }
  }, [location.pathname])

  return (
    <DesktopOverlayContextProvider>
      <div className="content">
        {location?.pathname !== ROUTES.DOWNLOAD &&
          location?.pathname !== ROUTES.DOWNLOAD_SNAP &&
          location?.pathname !== ROUTES.DOWNLOAD_FACEBOOK &&
          location?.pathname !== ROUTES.EARLY_ACCESS && <Cookie />}
        <Header
          isBlogLayout={pageContext?.layout === 'blog'}
          location={location?.pathname}
        />
        {children}
        <Footer location={location?.pathname} />

        {!isWaitlistPage &&
          location?.pathname !== ROUTES.DOWNLOAD &&
          location?.pathname !== ROUTES.DOWNLOAD_SNAP &&
          location?.pathname !== ROUTES.DOWNLOAD_FACEBOOK &&
          location?.pathname !== ROUTES.EARLY_ACCESS && <WaitingListBanner />}
      </div>
    </DesktopOverlayContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
