import React from 'react'

export function SvgQuoteNew({ width = 64, height = 65, fill, className }) {
  return <svg
    {...{ width, height, className }}
    viewBox="0 0 38 30"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M35.6904 0V6.2324H32.0988C29.2115 6.2324 27.7679 7.67606 27.7679 10.5634V13.9437H29.3523C31.8171 13.9437 33.8594 14.6831 35.4791 16.162C37.0988 17.6408 37.9087 19.507 37.9087 21.7606C37.9087 24.2253 37.0988 26.2324 35.4791 27.7817C33.8594 29.2606 31.8171 30 29.3523 30C26.8876 30 24.8453 29.2253 23.2256 27.6761C21.6763 26.0563 20.9017 23.9789 20.9017 21.4437V10.4577C20.9017 3.48592 24.3876 0 31.3594 0H35.6904ZM14.8805 0V6.2324H11.289C8.40167 6.2324 6.95799 7.67606 6.95799 10.5634V13.9437H8.54251C11.0073 13.9437 13.0495 14.6831 14.6693 16.162C16.289 17.6408 17.0988 19.507 17.0988 21.7606C17.0988 24.2253 16.289 26.2324 14.6693 27.7817C13.0495 29.2606 11.0073 30 8.54251 30C6.07772 30 4.03546 29.2253 2.41574 27.6761C0.866442 26.0563 0.0917969 23.9789 0.0917969 21.4437V10.4577C0.0917969 3.48592 3.57771 0 10.5495 0H14.8805Z" fill="#D7DFFF"/>
  </svg>
}
