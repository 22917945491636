// extracted by mini-css-extract-plugin
export var banner = "WaitingListBanner-module--banner--b196e";
export var header = "WaitingListBanner-module--header--5bdce";
export var light = "WaitingListBanner-module--light--af1ed";
export var link = "WaitingListBanner-module--link--6d3bf";
export var minimizeToggleBtn = "WaitingListBanner-module--minimizeToggleBtn--eaf83";
export var minimized = "WaitingListBanner-module--minimized--cf426";
export var outline = "WaitingListBanner-module--outline--12dfb";
export var small = "WaitingListBanner-module--small--ad756";
export var text = "WaitingListBanner-module--text--be808";
export var title = "WaitingListBanner-module--title--baeb1";
export var wrapper = "WaitingListBanner-module--wrapper--12012";