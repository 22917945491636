// Custom event names

export const SUBSCRIBE_WAITING_LIST = 'SignUpToWaitingList'
export const SUBSCRIBE_NEWSLETTER = 'SubscribeToNewsletter'
export const GET_APP_PRESS_FB = 'GetAppPress'
export const IOS_DOWNLOAD_PRESS_FB = 'DownloadOnTheAppstore'
export const ANDROID_DOWNLOAD_PRESS_FB = 'DownloadOnThePlaystore'
export const SMSF_DOWNLOAD_INFORMATION_DOCUMENT = 'DownloadInformationDocument'
export const SMSF_FORM_SUBMITION = 'SMSF form submission'

// Standart event names
// Full list of standart event names and description here:  https://developers.facebook.com/docs/facebook-pixel/reference#standard-events

export const CONTACT = 'Contact'
export const VIEW_CONTENT = 'ViewContent'
