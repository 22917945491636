exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-bamboo-consent-ui-index-jsx": () => import("./../../../src/pages/bamboo-consent-ui/index.jsx" /* webpackChunkName: "component---src-pages-bamboo-consent-ui-index-jsx" */),
  "component---src-pages-bitcoin-ethereum-index-js": () => import("./../../../src/pages/bitcoin-ethereum/index.js" /* webpackChunkName: "component---src-pages-bitcoin-ethereum-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-features-bam-rewards-index-js": () => import("./../../../src/pages/features/bam-rewards/index.js" /* webpackChunkName: "component---src-pages-features-bam-rewards-index-js" */),
  "component---src-pages-features-dollar-cost-average-js": () => import("./../../../src/pages/features/dollar-cost-average.js" /* webpackChunkName: "component---src-pages-features-dollar-cost-average-js" */),
  "component---src-pages-features-index-js": () => import("./../../../src/pages/features/index.js" /* webpackChunkName: "component---src-pages-features-index-js" */),
  "component---src-pages-features-portfolio-js": () => import("./../../../src/pages/features/portfolio.js" /* webpackChunkName: "component---src-pages-features-portfolio-js" */),
  "component---src-pages-features-rebalance-js": () => import("./../../../src/pages/features/rebalance.js" /* webpackChunkName: "component---src-pages-features-rebalance-js" */),
  "component---src-pages-features-round-ups-js": () => import("./../../../src/pages/features/round-ups.js" /* webpackChunkName: "component---src-pages-features-round-ups-js" */),
  "component---src-pages-features-top-ups-js": () => import("./../../../src/pages/features/top-ups.js" /* webpackChunkName: "component---src-pages-features-top-ups-js" */),
  "component---src-pages-fees-index-js": () => import("./../../../src/pages/fees/index.js" /* webpackChunkName: "component---src-pages-fees-index-js" */),
  "component---src-pages-gold-silver-index-js": () => import("./../../../src/pages/gold-silver/index.js" /* webpackChunkName: "component---src-pages-gold-silver-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invest-index-js": () => import("./../../../src/pages/invest/index.js" /* webpackChunkName: "component---src-pages-invest-index-js" */),
  "component---src-pages-partners-index-js": () => import("./../../../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-section-[id]-question-id-js": () => import("./../../../src/pages/[sectionId]/question/[id].js" /* webpackChunkName: "component---src-pages-section-[id]-question-id-js" */),
  "component---src-pages-security-index-js": () => import("./../../../src/pages/security/index.js" /* webpackChunkName: "component---src-pages-security-index-js" */),
  "component---src-pages-smsf-index-js": () => import("./../../../src/pages/smsf/index.js" /* webpackChunkName: "component---src-pages-smsf-index-js" */),
  "component---src-pages-support-index-js": () => import("./../../../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-waitlist-index-js": () => import("./../../../src/pages/waitlist/index.js" /* webpackChunkName: "component---src-pages-waitlist-index-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-crypto-curious-index-js": () => import("./../../../src/templates/crypto-curious/index.js" /* webpackChunkName: "component---src-templates-crypto-curious-index-js" */),
  "component---src-templates-legal-legal-template-js": () => import("./../../../src/templates/legal/legalTemplate.js" /* webpackChunkName: "component---src-templates-legal-legal-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-us-blog-index-js": () => import("./../../../src/templates/us-blog/index.js" /* webpackChunkName: "component---src-templates-us-blog-index-js" */),
  "component---src-templates-us-crypto-curious-index-js": () => import("./../../../src/templates/us-crypto-curious/index.js" /* webpackChunkName: "component---src-templates-us-crypto-curious-index-js" */)
}

