import classNames from 'classnames'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'

import * as styles from './Header.module.scss'
import Logo from '../../assets/new-icons/bamboo-logo.svg'
import TextLogo from '../../assets/new-icons/text_logo_small.svg'
import ROUTES from '../../constants/routes'
import { RegionContext } from '../../context/region-context'
import NotificationBanner from '../banners/notification-banner/NotificationBanner'
import BlogHeader from '../blog-header/BlogHeader'
import CountryDropdown from '../country-dropdown/CountryDropdown'
import Menu from '../menu/Menu'
import MobileMenu from '../mobile-menu/MobileMenu'
import GetBambooButton from '../ui/buttons/custom/get-bamboo-button/GetBambooButton'

function Header({ isNotificationBannerHidden, isBlogLayout, location }) {
  const region = useContext(RegionContext)
  const [scroll, setScroll] = useState(false)

  const getWindowStartScrolling = () => {
    const scroll = window.scrollY

    if (scroll > 0) {
      setScroll(true)
    } else setScroll(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', getWindowStartScrolling)

    return () => window.removeEventListener('scroll', getWindowStartScrolling)
  }, [])

  return (
    <header className={classNames(styles.header, scroll && styles.active)}>
      {/* {!isNotificationBannerHidden &&
        location !== ROUTES.DOWNLOAD &&
        location !== ROUTES.DOWNLOAD_SNAP &&
        location !== ROUTES.DOWNLOAD_FACEBOOK &&
        location !== ROUTES.EARLY_ACCESS &&
        !region?.isUSRegion && (
          <NotificationBanner
            text="Earn BAM Rewards for investing or referring friends and cash out when you like"
            redirectionPath="https://app.getbamboo.io/invest"
            isDownloadAppLink
          />
        )} */}
      <div className={styles.heroHeader}>
        <div className={styles.logo}>
          <Link
            to={region.isUSRegion ? ROUTES.US_HOME : ROUTES.HOME}
            title="Bamboo investments App"
          >
            <img
              src={Logo}
              width="32px"
              height="32px"
              alt="Bamboo investments App"
            />
            <img
              className={styles.bambooLogo}
              src={TextLogo}
              alt="Bamboo investments App"
            />
          </Link>
        </div>

        {location !== ROUTES.DOWNLOAD &&
          location !== ROUTES.DOWNLOAD_SNAP &&
          location !== ROUTES.DOWNLOAD_FACEBOOK &&
          location !== ROUTES.EARLY_ACCESS && <Menu />}

        <div className={styles.rightItems}>
          <GetBambooButton />
          {location !== ROUTES.DOWNLOAD &&
            location !== ROUTES.DOWNLOAD_SNAP &&
            location !== ROUTES.DOWNLOAD_FACEBOOK &&
            location !== ROUTES.EARLY_ACCESS && (
              <CountryDropdown
                className={styles.countryDropdown}
                {...{ location }}
              />
            )}
          {location !== ROUTES.DOWNLOAD && location !== ROUTES.EARLY_ACCESS && (
            <MobileMenu {...{ location }} />
          )}
        </div>

      </div>
      {isBlogLayout && <BlogHeader {...{ location }} />}
    </header>
  )
}

Header.propTypes = {
  isNotificationBannerHidden: PropTypes.bool,
  isBlogLayout: PropTypes.bool,
  location: PropTypes.string,
}

export default React.memo(Header)
